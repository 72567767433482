import React, { useEffect, useState } from 'react'
import { Divider, Stack, Typography, useMediaQuery } from '@mui/material'
import theme from '../../theme'
import GbImage from '../../images/token.png'
import Button from '@mui/material/Button'
import footballHeroesService from '../../services/FootballPlayerService'
import { useTheme } from '@emotion/react'
import { Skeleton } from '@mui/lab'
import Web3 from 'web3'
import moment from 'moment'

const ClaimPage = () => {
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
	const [seconds, setSeconds] = useState(0)
	const [hasStarted, setHasStarted] = useState(false)
	const [time, setTime] = useState({ 'h': 0, 'm': 0, 's': 0 })
	const [presaleToken, setPresaleToken] = useState(0)
	const [fetchingContribution, setFetchingContribution] = useState(true)
	const [amountClaimable, setAmountClaimable] = useState(0)

	const fetchContribution = async () => {
		setFetchingContribution(true)
		const [presaleToken, amount] = await Promise.all([
			footballHeroesService.getPresaleToken(),
			footballHeroesService.getNbTokenToClaim(),
		])
		setPresaleToken(presaleToken)
		setAmountClaimable(amount)
		setFetchingContribution(false)
	}

	useEffect(() => {
		fetchContribution()
	}, [])


	useEffect(() => {
		if (seconds === 0 && !hasStarted) {
			setHasStarted(true)
			setRewardTimer()
		}
		if (seconds > 0) {
			const timer = setTimeout(() => {
				setSeconds(seconds - 1)
				secondsToTime(seconds)
			}, 1000)
			return () => clearTimeout(timer)
		}
	}, [seconds])

	const secondsToTime = (secs) => {
		let hours = Math.floor(secs / (60 * 60))

		let divisorForMinutes = secs % (60 * 60)
		let minutes = Math.floor(divisorForMinutes / 60)

		let divisorForSeconds = divisorForMinutes % 60
		let seconds = Math.ceil(divisorForSeconds)

		setTime({
			'h': hours,
			'm': minutes,
			's': seconds
		})
	}

	const setRewardTimer = async () => {
		let timestamp = moment.unix(await footballHeroesService.getNextClaim())
		var duration = moment.duration(timestamp.diff(moment()))
		var seconds = duration.asSeconds()
		setSeconds(seconds)
	}

	return (
		<Stack sx={{ width: '100%' }}
			alignItems="center"
			spacing={2}
			   p={2}
		>
			<Stack
				sx={{ width: isMobile ? '95%' : '20%' }}
				alignItems="center"
				spacing={2}
			>

				<Stack
					direction="row"
					width="100%"
					spacing={1}
					alignItems="center"
					justifyContent="space-between"
				>
					<Typography variant="subtitle1">Tokens received in presale:</Typography>
					<Stack direction="row" alignItems="center" spacing={0.5}>
						<Typography variant="subtitle1" color="secondary">{
							fetchingContribution ?
								<Skeleton width={15} />
								:
								Web3.utils.fromWei(presaleToken || '0', 'ether')
						}</Typography>
						<img style={{ width: 20, height: 20 }} src={GbImage} alt="gb"/>
					</Stack>
				</Stack>
				<Stack
					direction="row"
					width="100%"
					spacing={1}
					alignItems="center"
					justifyContent="space-between"
				>
					<Typography variant="subtitle1">Claimable token:</Typography>
					<Stack direction="row" alignItems="center" spacing={0.5}>
						<Typography variant="subtitle1" color="secondary">{
							fetchingContribution ?
								<Skeleton width={15} />
								:
								Web3.utils.fromWei(amountClaimable || '0', 'ether')
						}</Typography>
						<img style={{ width: 20, height: 20 }} src={GbImage} alt="gb"/>
					</Stack>
				</Stack>
				<Divider flexItem variant="middle"/>
				<Stack
					direction="row"
					spacing={1}
					width="100%"
					alignItems="center"
					justifyContent="space-between"
				>
					<Typography variant="subtitle1">Next claim:</Typography>
					<Typography variant="caption">{time.h}h{time.m}m{time.s}s</Typography>
				</Stack>
				<Button
					// disabled={seconds > 0 || !hasStarted || fetchingContribution}
					disabled
					onClick={() => {
						footballHeroesService.presaleClaim()
					}}
					variant="contained"
					color="secondary"
					fullWidth
				>
					Claim
				</Button>
			</Stack>

		</Stack>
	)
}

export default ClaimPage
