export const darkModal = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: 400,
	bgcolor: '#181818',
	border: '2px solid #000',
	boxShadow: 24,
	p: 4,
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'center',
	alignItems: 'center',
}

export const darkModalNoFlex = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	bgcolor: 'background.paper',
	border: '1px solid #9b7e00',
	boxShadow: '0 0 10px #d0ad34',
	borderRadius: '5px',
	p: 2,
	outline: 'none',
	overflow: 'hidden',
}

export const trainingModal = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	bgcolor: 'background.paper',
	border: '1px solid #9b7e00',
	boxShadow: '0 0 10px #d0ad34',
	borderRadius: '5px',
	p: 2,
	outline: 'none',
	overflow: 'hidden',
}
