import React, { useEffect, useState } from 'react'
import {
	Card,
	CardActionArea,
	CardActions,
	CardContent,
	CardHeader,
	CardMedia, CircularProgress,
	Grid,
	Stack, Tab, Tabs,
	Typography, useMediaQuery
} from '@mui/material'
import football3 from '../../images/football-3.jpg'
import Button from '@mui/material/Button'
import TokenPrice from '../../components/tokenPrice/TokenPrice'
import theme from '../../theme'
import footballHeroesService from '../../services/FootballPlayerService'
import { useSelector } from 'react-redux'
import Box from '@mui/material/Box'
import PackOne from '../../images/sac1trans.png'
import PackTwo from '../../images/sac2trans.png'
import PackThree from '../../images/sac3.png'
import Frame from '../../enums/Frame'
import { Skeleton } from '@mui/lab'


const PackPage = () => {
	const {
		isMintPackOpen,
		isWhiteListed,
		packOpen,
		event,
	} = useSelector(state => state.settings)
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
	const [value, setValue] = useState(1)
	const [myPacks, setMyPacks] = useState([])
	const [isFetching, setIsFetching] = useState(true)
	const [packAmount, setPackAmount] = useState([0, 0, 0])

	const fetchData = async () => {
		setIsFetching(true)
		const packAvailable = await footballHeroesService.getPackAvailable()
		const packs = await footballHeroesService.getPacks()
		setMyPacks(packs)
		setPackAmount(packAvailable)
	}

	useEffect(() => {
		if (event === 'mintPack') {
			fetchData().finally(setIsFetching(false))
		}
	}, [event])

	useEffect(() => {
		fetchData().finally(setIsFetching(false))
	}, [])

	const TabPanel = (props) => {
		const { children, value, index, ...other } = props

		return (
			<div
				role="tabpanel"
				hidden={value !== index}
				{...other}
				style={{ width: '100%' }}
			>
				{value === index && (
					<Box sx={{ p: 3 }}>
						{children}
					</Box>
				)}
			</div>
		)
	}

	const packs = [
		{
			id: 0,
			image: PackOne,
			frame: 2,
			price: 1,
			title: 'Starter Pack',
			description: ['5 players', '5 silver players secured', '70 score minimum']
		},
		{
			id: 1,
			image: PackTwo,
			price: 2,
			frame: 3,
			title: 'Epic Pack',
			description: ['11 players', '6 silver players secured', '60 score minimum', 'Bronze minimum']
		},
		{
			id: 2,
			image: PackThree,
			price: 2.5,
			frame: 4,
			title: 'Legendary Pack',
			description: ['11 players', '5 gold players secured', '80 score minimum', 'Silver minimum']
		},
	]

	const Pack = ({ id, title, description, image, frame, price, open = false }) => {

	    return (
			<Card>
				<CardActionArea>
					<CardMedia
						component="img"
						height="300"
						sx={{
							objectFit: 'contain',
							boxShadow: `0px 0px 5px ${Frame.TierList[frame].color.dark}, inset 0px 0px 250px ${Frame.TierList[frame].color.main}`,
							background: `radial-gradient(at 50% 0, ${Frame.TierList[frame].color.dark}, transparent 70%),linear-gradient(0deg, ${Frame.TierList[frame].color.dark}, transparent 50%) bottom`,
							border: `1px solid ${Frame.TierList[frame].color.light}`,
						}}
						image={image}
						alt="pack"
					/>
					<CardContent>
						<Typography lineHeight={2} gutterBottom variant="h5">
							{ title }
						</Typography>
						<Stack>
							{
								description && description.map(d =>
									<Typography key={d} gutterBottom variant="caption" color="text.secondary">
										{ d }
									</Typography>
								)
							}
						</Stack>
					</CardContent>
				</CardActionArea>
				<CardActions>
					<Stack direction="row" spacing={2} alignItems="center" width="100%">
						{
							open ?
								<>
									<Button
										variant="contained"
										size="small"
										color="primary"
										disabled={!packOpen}
										onClick={() => {
											footballHeroesService.openPack(id)
										}}
									>
										Open
									</Button>
									{
										!packOpen &&
										<Typography color="secondary" variant="caption">You can't open it yet</Typography>
									}
								</>
								:
								<>
									<Button
										variant="contained"
										size="small"
										color="primary"
										disabled={!isMintPackOpen || !isWhiteListed || +packAmount[id] === 0}
										onClick={() => {
											footballHeroesService.mintPack(id, 1, packs[id].price)
										}}
										endIcon={
											<TokenPrice
												typoVariant="caption"
												price={price}
												size={15}
												token="bnb"
											/>
										}>
										Buy
									</Button>
									<Stack direction="row" spacing={0.5} alignItems="center">
										<Typography variant="subtitle2" color="secondary">
											Pack left:
										</Typography>
										<Typography  variant="subtitle1" color="secondary">
											{
												isFetching ?
													<Skeleton width={20} variant="text"/>
													:
													packAmount[id]
											}
										</Typography>
									</Stack>
								</>
						}
					</Stack>
				</CardActions>
			</Card>
		)
	}

	return (
		<Stack
			width="100%"
			alignItems="center"
			p={2}
		>
			<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
				<Tabs
					textColor="secondary"
					indicatorColor="secondary"
					value={value} onChange={(event, newValue) => {
						setValue(newValue)
					}}>
					{
					//	<Tab label="Buy Packs" />
					}
					<Tab label="My Packs" />
				</Tabs>
			</Box>
			<TabPanel value={value} index={0}>
				<Grid container columns={12} spacing={2} justifyContent="center">
					{
						packs.map(p =>
							<Grid item key={p.id} xs={isMobile ? 10 : 3}>
								<Pack {...p} />
							</Grid>
						)
					}
				</Grid>
			</TabPanel>
			<TabPanel value={value} index={1}>
				<Grid container columns={12} spacing={2} justifyContent="center">
					{
						isFetching ?
							<Box sx={{
								height: '80vh',
								display: 'flex',
								width: '100%',
								justifyContent: 'center',
								alignItems: 'center',
							}}>
								<CircularProgress color="secondary"/>
							</Box>
							:
							myPacks.length === 0 ?
								<Box sx={{
									height: '80vh',
									display: 'flex',
									width: '100%',
									justifyContent: 'center',
									alignItems: 'center',
								}}>
									<Typography>
									You don't have any pack
									</Typography>
								</Box>
								:
								myPacks.map(p => (
									<Grid item key={'pack' + p.id} xs={isMobile ? 10 : 3}>
										<Pack
											open {...packs[+p.packId]}
										/>
									</Grid>)
								)
					}
				</Grid>
			</TabPanel>
		</Stack>
	)
}

export default PackPage
