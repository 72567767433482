// This address points to a dummy ERC20 contract deployed on Ethereum Mainnet,
// Goerli, Kovan, Rinkeby and Ropsten. Replace it with your smart contracts.
const addresses = {
	BUSDTestnet: '0x78867bbeef44f2326bf8ddd1941a4439382ef2a7',
	BUSD: '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
	GBTOKEN: '0x693e68d4046d4276a00F08106a81A44FC03e2685',
	Marketplace: '0xaBb5F8d120fAeddc546494F59fE9085D3ac0b0C4',
	Game: '0x496Bc0b17D3f78B2dB5d7e2375202448071F5d2c',
	FootballPlayers: '0x644cdf26C75091AA74f5d29401374E0B030B1fae',
	FootballHeroesStorage: '0x893E9A5dA26f115D3162998429aFd9f636b55228',
	pack: '0x3d3337E3A704794E6D16a3cC0F76112c601eA1AB',
	presale: '0xf17c245993503b71fa318a01344d644a2310cd42',
}
/*
[0,"0",3, 4],[[0,"1",3, 4],[1,"1",3,4],[2,"1",3,4],[3,"1",3,4]],[[0,"2",3,4],[1,"2",3,4],[2,"2",3,4],[3,"2",3,4]],[[0,"3",3,4],[1,"3",3,4]],"100","100"
[0,"0",2, 3],[[0,"1",2, 3],[1,"1",2,3],[2,"1",2,3],[3,"1",2,3],[4,"1",2,3]],[[0,"2",2,3],[1,"2",2,3],[2,"2",2,3]],[[0,"3",2,3],[1,"3",2,3]],"80","80"
[0,"0",1, 2],[[0,"1",1, 2],[1,"1",1,2],[2,"1",1,2],[3,"1",1,2]],[[0,"2",1,2],[1,"2",1,2],[2,"2",1,2]],[[0,"3",1,2],[1,"3",1,2],[2,"3",1,2]],"60","60"
[0,"0",0, 1],[[0,"1",0, 1],[1,"1",0,1],[2,"1",0,1],[3,"1",0,1]],[[0,"2",0,1],[1,"2",0,1],[2,"2",0,1],[3,"2",0,1],[4,"2",0,1]],[[0,"3",0,1]],"40","40"
0x1ab1cec14dc4831decf8b774dce47242bed3b8d0
 */
export default addresses
