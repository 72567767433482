import React, { useEffect, useState } from 'react'
import { Box, Divider, Grid, Slide, Stack, Typography, useMediaQuery } from '@mui/material'
import Strategy from '../../../enums/Strategy'
import Button from '@mui/material/Button'
import Position from '../../../enums/Position'
import LayoutContent from '../../../components/LayoutContent'
import PlayerListItem from './PlayerListItem'
import { useDispatch, useSelector } from 'react-redux'
import { removePlayerFromTeamById, resetTeam, setStrategy } from '../../../features/gameSlice'
import footballHeroesService from '../../../services/FootballPlayerService'
import { useTheme } from '@emotion/react'
import { Remove } from '@mui/icons-material'
import { useNavigate } from 'react-router-dom'

const DrawerContent = ({ lastPlayerDropped }) => {
	const { team, collection } = useSelector(state => state.game)
	const dispatch = useDispatch()
	const theme = useTheme()
	const [compositions, setCompositions] = useState([])
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
	const navigate = useNavigate()


	useEffect(() => {
	}, [])

	const fetch = async () => {
		if (Strategy.Strategies.length === 0) {
			const response = await footballHeroesService.getCompositionList()
			response.forEach(compo => {
				Strategy.Strategies.push(new Strategy(Strategy.Strategies.length,
					`${compo.attackerNb} - ${compo.midfielderNb} - ${compo.defenderNb}`, {
						[Position.Attacker.id]: compo.attackerNb,
						[Position.Midfielder.id]: compo.midfielderNb,
						[Position.Defender.id]: compo.defenderNb,
						[Position.GoalKeeper.id]: 1,
					}))
			})
		}
	}

	const selectStrategy = async (strategy) => {
		dispatch(setStrategy(strategy.id))
	}

	const resetStrategy = () => {
		dispatch(resetTeam())
	}

	const destroyTeam = async () => {
		await footballHeroesService.resetTeam()
	}

	const saveTeam = async () => {
		if (team.players.length !== 11) {
			return
		}
		const composition = {
			goalkeeper: undefined,
			defenders: [],
			midfielders: [],
			attackers: [],
		}
		team.players.forEach(p => {
			switch (+p.position) {
			case Position.Attacker.id:
				composition.attackers.push(+p.id)
				break
			case Position.Midfielder.id:
				composition.midfielders.push(+p.id)
				break
			case Position.Defender.id:
				composition.defenders.push(+p.id)
				break
			case Position.GoalKeeper.id:
				composition.goalkeeper = +p.id
				break
			}
		})
		await footballHeroesService.setPlayerTeam(
			+team.strategy,
			+composition.goalkeeper,
			composition.defenders,
			composition.midfielders,
			composition.attackers,
		)
	}

	return (
		<Box sx={{
			flexGrow: 1,
			width: '100%',
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
			justifyContent: 'flex-start'
		}}>
		</Box>
	)
}

export default DrawerContent
