/* global BigInt */
import React, { useEffect, useState } from 'react'
import { Button, Divider, Input, LinearProgress, Stack, Typography, useMediaQuery } from '@mui/material'
import GbImage from '../../images/token.png'
import BnbImage from '../../images/bnb.png'
import theme from '../../theme'
import footballHeroesService from '../../services/FootballPlayerService'
import { useSelector } from 'react-redux'
import { LoadingButton, Skeleton } from '@mui/lab'
import Web3 from 'web3'
import moment from 'moment'

const PresalePage = () => {
	const {
		presaleOpen,
		isWhiteListed,
		gbPrice,
		event,
		maxContribution,
		maxTokens,
	} = useSelector(state => state.settings)
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
	const [isInTransaction, setIsInTransaction] = useState(false)
	const [tokens, setTokens] = useState({
		bnb: '',
		gb: '',
	})
	const [currentContribution, setCurrentContribution] = useState(0)
	const [presaleToken, setPresaleToken] = useState(0)
	const [globalContribution, setGlobalContribution] = useState(0)
	const [fetchingContribution, setFetchingContribution] = useState(false)
	const [tokensLeft, setTokensLeft] = useState(0)
	const [percentage, setPercentage] = useState(0)
	const [seconds, setSeconds] = useState(0)
	const [hasStarted, setHasStarted] = useState(false)
	const [time, setTime] = useState({ 'h': 0, 'm': 0, 's': 0 })

	const fetchContribution = async () => {
		setFetchingContribution(true)
		const [presaleAmount, contribution, global] = await Promise.all([
			footballHeroesService.getPresaleToken(),
			footballHeroesService.presaleGetCurrentContribution(),
			footballHeroesService.presaleGetGlobalContribution(),
		])
		setPresaleToken(presaleAmount)
		setCurrentContribution(contribution)
		setGlobalContribution(global)
		setFetchingContribution(false)
		setTokensLeft(Web3.utils.fromWei(maxTokens) - Web3.utils.fromWei(global) * gbPrice)
		setPercentage(
			(Web3.utils.fromWei(maxTokens) - Web3.utils.fromWei(global) * gbPrice)
			/
				Web3.utils.fromWei(maxTokens) * 100
		)

	}


	useEffect(() => {
		if (seconds === 0 && !hasStarted) {
			setHasStarted(true)
		}
		if (seconds > 0) {
			const timer = setTimeout(() => {
				setSeconds(seconds - 1)
				secondsToTime(seconds)
			}, 1000)
			return () => clearTimeout(timer)
		}
	}, [seconds])

	const secondsToTime = (secs) => {
		let hours = Math.floor(secs / (60 * 60))

		let divisorForMinutes = secs % (60 * 60)
		let minutes = Math.floor(divisorForMinutes / 60)

		let divisorForSeconds = divisorForMinutes % 60
		let seconds = Math.ceil(divisorForSeconds)

		setTime({
			'h': hours,
			'm': minutes,
			's': seconds
		})
	}


	useEffect(() => {
		// footballHeroesService.getTimeLeft().then(
		//	timeLeft => setSeconds(timeLeft))
		fetchContribution()
	}, [])

	useEffect(() => {
		if (event === 'contribute') {
			fetchContribution()
		}
	}, [event])

	const handleInputChange = (token, event) => {
		let value = parseFloat(event.target.value)
		if (isNaN(value)) {
			setTokens({
				gb: '',
				bnb: '',
			})
			return
		}
		const max = Web3.utils.fromWei(maxContribution, 'ether')
		if (token === 'gb' && value > (max * gbPrice)) {
			value = (max * gbPrice)
		} else if (token === 'bnb' && value > max) {
			value = max
		}
		setTokens({
			[token]: value,
			[token === 'bnb' ? 'gb' : 'bnb']: token === 'bnb' ? (value * gbPrice) > maxContribution ? maxContribution : (value * gbPrice) : value / gbPrice
		})
	}

	if (presaleOpen === false) {
		return (
			<Stack alignItems="center" justifyContent="center" sx={{ height: '80vh',  width: '100%' }} p={2} spacing={2}>
				<Typography variant="h5">Presale is closed</Typography>
				<Typography variant="h5">Whitelisted: { isWhiteListed ? 'Yes' : 'No'}</Typography>
			</Stack>
		)
	}

	return (
		<Stack alignItems="center" sx={{ width: '100%' }} p={2} spacing={2}>
			<Typography variant="h4" color="secondary">Presale</Typography>
			<Divider flexItem />

			<Stack sx={{
				width: isMobile ? '95%' : '30%'
			}}
			spacing={2}
			>
				<Stack 	sx={{ width: '100%' }} alignItems="center" spacing={1}>
					<Typography variant="subtitle2" color="secondary">
						Current contribution:
					</Typography>
					<Divider flexItem variant="middle"/>
					<Stack direction="row" justifyContent="space-around" alignItems="center" spacing={2}>
						<Stack direction="row" alignItems="center" spacing={1}>
							<Typography variant="subtitle1" color="secondary">{
								fetchingContribution ?
									<Skeleton width={10}/>
									:
									Web3.utils.fromWei(currentContribution || '0', 'ether')
							}</Typography>
							<img style={{ width: 20, height: 20 }} src={BnbImage} alt="bnb"/>
						</Stack>
						<Stack direction="row" alignItems="center" spacing={1}>
							<Typography variant="subtitle1" color="secondary">{
								fetchingContribution ?
									<Skeleton width={10}/>
									:
									Web3.utils.fromWei(presaleToken || '0', 'ether')
							}</Typography>
							<img style={{ width: 20, height: 20 }} src={GbImage} alt="gb"/>
						</Stack>
					</Stack>
					<Divider flexItem variant="middle"/>
				</Stack>
				<Stack sx={{ width: '100%', alignItems: 'center' }}>
					<Typography variant="h5" color="secondary" sx={{ mb: 1 }}>
						Token left:
					</Typography>
					<LinearProgress sx={{ width: '100%' }} color="secondary" variant="determinate" value={percentage} />
					<Typography variant="caption" color="secondary">
						{
							fetchingContribution ?
								<Skeleton width={30}/>
								:
								tokensLeft}
						/
						{Web3.utils.fromWei(maxTokens, 'ether')}
					</Typography>
				</Stack>
				<Divider flexItem variant='fullWidth'/>
				<Stack sx={{ width: '100%', alignItems: 'center' }}>
					<Typography variant="h5" color="secondary" sx={{ mb: 1 }}>
						Time left:
					</Typography>
					<Typography variant="caption">{time.h}h{time.m}m{time.s}s</Typography>
				</Stack>
				<Divider flexItem variant='fullWidth'/>
				<Stack sx={{ width: '100%', alignItems: 'center' }} spacing={1} p={2}>
					<Typography textAlign="center">
						Enter the amount of bnb you want to convert or the amount of gb you want to buy ( {Web3.utils.fromWei(maxContribution, 'ether')} BNB max per person )
					</Typography>
					<Input
						sx={{
							padding: '2px',
						}}
						color="secondary"
						value={tokens.bnb}
						size="small"
						endAdornment={
							<img style={{ width: 20, height: 20 }} src={BnbImage} alt="Bnb"/>
						}
						onChange={(e) => {
							handleInputChange('bnb', e)
						}}
						onBlur={(e) => {
							handleInputChange('bnb', e)
						}}
						inputProps={{
							placeholder: '1',
							type: 'number',
						}}
					/>
					<Input
						sx={{
							padding: '2px',
						}}
						color="secondary"
						value={tokens.gb}
						size="small"
						endAdornment={
							<img style={{ width: 20, height: 20 }} src={GbImage} alt="gb token"/>
						}
						onChange={(e) => {
							handleInputChange('gb', e)
						}}
						onBlur={(e) => {
							handleInputChange('gb', e)
						}}
						inputProps={{
							placeholder: gbPrice,
							type: 'number',
						}}
					/>
				</Stack>
				<Divider flexItem variant='fullWidth'/>
				<LoadingButton
					loading={isInTransaction}
					disabled={tokens.bnb === '' || tokens.bnb === ''}
					onClick={() => {
						setIsInTransaction(true)
						footballHeroesService.contribute(tokens.bnb).finally(() => setIsInTransaction(false))
					}}
					variant="contained"
					color="secondary"
				>Confirm</LoadingButton>
			</Stack>


		</Stack>
	)
}

export default PresalePage
